import { MESSAGES_API_ENDPOINT } from "../Constants";

export default class MessagesService {
  public static getDirectMessageUrl(tenant: string, instance: string): Promise<string>{
    return new Promise((resolve, reject) => {
      if (tenant) {
        const apiUrl = `${MESSAGES_API_ENDPOINT}/${tenant.replace(".sharepoint.com", "")}/${instance}/direct-message-url`;
        fetch(apiUrl, {
          method: "GET",
          credentials: "same-origin",
          headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }) 
        })
          .then((response) => {
            if (!response.ok) {
              reject(response.status);
            } else {
              return response.json();
            }
          }).then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      } else {
        reject("Missing parameter");
      }
    });
  }
}