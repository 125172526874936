import * as React from 'react';
import { render } from 'react-dom';
import './index.css';
import LinkPortal from './LinkPortal';
import { Provider } from 'mobx-react';
import { Store } from './stores';

declare global {
  interface Window { linkPortal: any; }
}

render(
  <Provider store={new Store()}>
    <LinkPortal ref={(linkPortal: any) => { window.linkPortal = linkPortal.wrappedInstance }} />
  </Provider>,
  document.getElementById('root')
);